<template>
  <div class="document-list-container container">
    <DocumentListItem
      class="document-list-item"
      v-for="documentListItem in documentListData"
      :key="documentListItem.id"
      :document-item="documentListItem"
    />
  </div>
</template>

<script>
import DocumentListItem from '@/components/Documents/DocumentListItem'
export default {
  name: 'DocumentListContainer',
  components: { DocumentListItem },
  props: {
    documentListData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.document-list-container {
  @apply flex flex-wrap justify-center;

  .document-list-item {
    @apply w-full mb-10;
  }

  @media screen and (min-width: 600px) {
    .document-list-item {
      @apply w-1/2 pr-14;
    }
  }

  @media screen and (min-width: 900px) {
    .document-list-item {
      @apply w-1/3;
    }
  }
}
</style>
