<template>
  <div class="document-list-item">
    <h4>{{ documentItem.titleText }}</h4>
    <p>{{ documentItem.description }}</p>
    <AnchorLink
      class="anchor-link"
      :anchor-link="documentUpload.url"
      :title="documentUpload.filename"
      download
    >
      <div class="document-icon">
        <FontAwesomeIcon :icon="documentIcon" />
      </div>
      <FontAwesomeIcon icon="download" /> Download ({{ documentSize }})
    </AnchorLink>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'DocumentListItem',
  components: { AnchorLink },
  props: {
    documentItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    documentUpload() {
      return this.documentItem.document[0]
    },
    documentIcon() {
      let explodedDocument = this.documentUpload.url.split('.')
      const documentExtension = explodedDocument[explodedDocument.length - 1]

      switch (documentExtension) {
        case 'doc':
        case 'docx':
          return 'file-word'
        case 'pdf':
          return 'file-pdf'
        default:
          return 'file-alt'
      }
    },
    documentSize() {
      return Math.ceil(this.documentUpload.size / 1000) + 'KB'
    },
  },
}
</script>

<style lang="scss" scoped>
.document-list-item {
  color: #cfcfcf;

  h4 {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 23px;
  }

  .anchor-link {
    @apply block;
    color: #f17800;
    transition: color 0.3s ease;

    &:hover {
      color: #d76b00;
    }

    .document-icon {
      color: #cfcfcf;
      font-size: 72px;
    }
  }
}
</style>
