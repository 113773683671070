<template>
  <div id="patientFormsView">
    <SimpleContentBlock
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <DocumentListContainer :document-list-data="documentListData" />
  </div>
</template>

<script>
import api from '@/api'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
import helpers from '@/api/helpers/helpers'
import DocumentListContainer from '@/components/Documents/DocumentListContainer'
import { mapMutations } from 'vuex'
export default {
  name: 'PatientForms',
  components: { DocumentListContainer, SimpleContentBlock },
  data() {
    return {
      simpleContentData: null,
      documentListData: [],
      loaded: false,
    }
  },
  mounted() {
    this.loadPatientFormsView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadPatientFormsView() {
      this.setPatientFormsData(helpers.readStoredResponse('view.patient-forms'))

      api.views.loadPatientForms().then((response) => {
        this.setPatientFormsData(response)
      })
    },
    setPatientFormsData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.simpleContentData = entry.simpleContentBlock[0] || null
      this.documentListData = entry.documentList || []

      if (!this.loaded) {
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 500)
        this.loaded = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#patientFormsView {
  @apply bg-black;
  margin-bottom: 200px;
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 700px;
}
</style>
